<template>
  <v-container fluid class="grey lighten-3 pa-0" :key="myKey" ref="bracketContainer" dark>
    <!-- MATCH STYLE ALERT -->
    <v-alert
      :value="!demo"
      type="info"
      tile dense
      class="mb-1"
      :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
    >
      {{matchDByR}}
    </v-alert>
    <v-row dense v-if="true" justify="end" class="not-printable">
      <v-btn color="color3" data-html2canvas-ignore text small v-if="demo && losers.length" @click.stop="iWinners = !iWinners">Show {{iWinners ? 'Contenders' : 'Winners'}}</v-btn>
      <v-btn color="color3" data-html2canvas-ignore text small v-if="!demo" @click.stop="showMatch = !showMatch">{{showMatch ? 'Hide' : 'Show'}} Match Format</v-btn>
      <v-btn color="color3" data-html2canvas-ignore text small v-if="!demo" @click.stop="showMap = !showMap">{{showMap ? 'Hide' : 'Show'}} Map</v-btn>
      <v-btn color="color3" data-html2canvas-ignore text small @click.stop="showByes = !showByes">{{showByes ? 'Hide' : 'Show'}} Byes</v-btn>
    </v-row>
    <!-- BRACKET VIEW -->
    <v-row dense class="bracket-container pb-4" v-if="bracket && bracketView" @scroll.passive="onScroll" :id="`bracket-${bracket.id}`">
      <div class="bracket" ref="bracket" :class="{'bracket-logo': useLogos}">
        <div v-for="(round, r) in rounds"
          :key="`round${r}`"
          class="round"
          :class="roundProps[r].class"
          :style="roundProps[r].style"
        >
          <bracket-match-card
            v-for="(match) in round.matches"
            :key="`${bracket.id}-${match.number}`"
            :matchIn="match"
            class="match"
            :id="`match${match.number}`"
            :ref="`match${match.number}`"
            :class="{ hide: match.isBye && !showByes }"
            style="min-height: 75px; z-index: 1"
            :showMap="showMap"
            :showByes="showByes"
            :showMatch="showMatch"
            :bracket="bracket"
            @click.native="!demo && selectMatch(match)"
            @auto-click="selectMatch(match)"
            :showFooter="!demo"
            @size-change="onScroll"
            :lineText="r === rounds.length - 1 && bracket.champions > 1 ? (champText || 'Advances to Main Draw') : null"
            :mapper="mapper"
            :map="getMap(match)"
            :highlight="highlights.length > 0 && highlights.includes(match.number)"
            :lowlight="highlights.length > 0 && !highlights.includes(match.number)"
            :nums="nums"
          ></bracket-match-card>
        </div>
        <template v-for="(match) in roundMatches">
          <template v-for="next in bracket.nextMatches(match.number, match.isWinners)">
            <svg-connector
              :key="`brackt-match${match.number}-to-${next.match.number}`"
              v-if="!(match.isBye && !showByes)"
              :start-anchor="{id: `brackt-match${match.number}`, x: '100%', y: '50%'}"
              :end-anchor="{id: `brackt-match${next.match.number}`, x: 50, y: 0}"
              :container="`bracket-${bracket.id}`"
              :line-style="next.winners ? 'solid' : 'dashed'"
              :trigger="hiddenRounds"
              :lowlight="highlights.length > 0 && (!highlights.includes(match.number) || !highlights.includes(next.match.number))"
            ></svg-connector>
          </template>
          <match-in-out-text
            v-if="match.advanceText(bracket, (match.round === lastRound && bracket.champions > 1 ? (champText || 'Advances to Main Draw') : null))"
            :key="`${bracket.id}-${match.number}-connect-2`"
            :match="`#match${match.number}`"
            :text="match.advanceText(bracket, (match.round === lastRound && bracket.champions > 1 ? (champText || 'Advances to Main Draw') : null))"
            :container="`bracket-${bracket.id}`"
            :nudgeX="40"
            :trigger="hiddenRounds"
            :in="true"
          />
        </template>
      </div>
    </v-row>
    <v-row v-if="!demo && view.admin">
      <v-col cols="6" class="xsmall">
        <team-message-button
          :tournamentId="tournament.id"
          :teams="bracket.teams"
          :xs="true"
        ></team-message-button>
      </v-col>
      <v-col cols="6" class="text-end">
        <match-print v-if="tournament.isNcaa && view.adminAndRefs"
          :tournament="tournament"
          :division="division"
          :round="round"
          :matches="bracket.matches.filter(f => !f.isBye && f.isDual)"
          :pool="null"
          :block="false"
          :text="true"
          :xSmall="true"
        ></match-print>
      </v-col>
    </v-row>
    <v-row v-if="!demo && user && user.vbl">
      <v-col cols="12" class="xsmall">
        Bracket Id: {{bracket.id}}
      </v-col>
    </v-row>
    <!-- LIST VIEW -->
    <v-row dense v-if="bracket && !bracketView" class="pa-1">
      <v-col cols="12">
        <v-expansion-panels multiple v-model="panel" ref="xPanel">

          <v-expansion-panel
            v-for="(round, r) in rounds" :key="`round${r}`"
            :style="r > 0 ? 'border-top: 1px solid white !important' : null"
            class="grey lighten-4"
          >
            <div slot="header" class="title"></div>
            <v-expansion-panel-header color="color2 color2Text--text" class="title">
              Round {{r + 1}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
                <div
                  class="my-2"
                  v-for="(match) in round.matches.filter(f => !f.isBye)"
                  :key="`round${match.round}-match${match.number}`"
                >
                  <match-card
                    :matchIn="match"
                    :bracketCard="true"
                    :showMap="showMap"
                    :bracket="bracket"
                    :teamColors="teamColors"
                    :round="round"
                    :division="division"
                  ></match-card>
                </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-col>
    </v-row>
    <!-- PLACE MATCHES -->
    <template v-if="!demo && user && user.id === 1">
      <v-divider></v-divider>
      <v-row dense class="pa-4">
        <v-col cols="12">
          <v-btn
            color="success"
            x-small text
            @click.stop="nums = !nums"
          >Toggle Numbers</v-btn>
        </v-col>
        <v-col>
          <v-text-field
            label="Place Match Between"
            v-model="places"
          >
            <template v-slot:append-outer>
              <v-btn
                color="success"
                small
                :disabled="!addDto"
                :loading="loading"
                @click.stop="addPlaceMatch"
              >
                add
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>

    <!-- MATCH DIALOG -->
    <v-dialog
      v-if="selectedMatch"
      v-model="matchDialog"
      scrollable
      :persistent="false"
      width="90vw"
      max-width="800px"
      transition="dialog-transition"
    >
      <match-card
        v-if="!bracket.isDuals"
        :key="`${bracket.id}-${selectedMatch.number}-${selectedMatch.id}`"
        style="width: 100%"
        :matchIn="selectedMatch"
        :bracketCard="true"
        :showMap="showMap"
        :bracket="bracket"
        :teamColors="teamColors"
        @match-saved="onMatchSaved"
        :showMatchSettings="matchDByR.startsWith('Not')"
        :round="round"
        :division="division"
      ></match-card>
      <dual-card
        v-else
        :dual="selectedMatch"
        :bracketCard="true"
        :showMap="showMap"
        :bracket="bracket"
        @match-saved="onMatchSaved"
        :myKey="bracket.id"
        :round="round"
        :division="division"
      ></dual-card>
    </v-dialog>
    <!-- VIEW FAB -->
    <template v-if="!noFabs">
      <bracket-search
        @highlight="onHighlight"
        v-if="bracketSearch && !demo"
        :bracket="bracket"
      ></bracket-search>
      <v-tooltip left v-else-if="!demo">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            color="color3Text color3--text"
            class="not-printable"
            data-html2canvas-ignore
            bottom
            right
            fixed
            @click.stop="bracketView = !bracketView"
          >
            <v-icon v-if="bracketView">fas fa-list</v-icon>
            <v-icon v-else>fas fa-project-diagram</v-icon>
          </v-btn>
        </template>
        <span v-if="bracketView">List View</span>
        <span v-else>Bracket View</span>
      </v-tooltip>
      <v-btn
        fab
        v-if="!demo && view.admin"
        color="color3Text color3--text"
        class="not-printable"
        data-html2canvas-ignore
        bottom
        right
        fixed
        style="margin-right: 60px"
        @click.stop="onPrintClick2"
        :loading="printing"
      >
        <v-icon>far fa-print</v-icon>
      </v-btn>
      <court-wizard
        data-html2canvas-ignore
        v-if="!demo && view.admin && !locked && false"
        ref="courtWiz"
        class="not-printable"
        @open-change="onOpenChange"
        :bracket="bracket"
      ></court-wizard>
      <add-courts
        v-if="!demo && user && (user.vbl || user.id === 1228)"
        :bracket="bracket"
      ></add-courts>
    </template>
    <div v-if="!demo && user && user.vbl">Courts Used: {{courtsUsed | formatArray}}</div>
    <div v-if="!demo && user && user.vbl">Pool Courts: {{poolCourts | formatArray}}</div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
import PoolPlayOptionDescriptor from '@/classes/PoolPlayOptionDescriptor'
import RoundMixin from '@/Mixins/RoundMixin'
import RouteMixin from '@/Mixins/RouteMixin'
import SvgConnector from '@/components/Utils/SvgConnector'
import groupby from 'lodash.groupby'
import courtSort from '@/helpers/CourtSort'
import MatchCard from '@/components/Tournament/Match/FullMatchCard.vue'
import BracketMatchCard from '@/components/Tournament/Match/BracketMatchCard.vue'
import MatchInOutText from '@/components/Tournament/Brackets/MatchInOutText'
import BracketSearch from '@/components/Tournament/Brackets/BracketSearch.vue'
const CourtWizard = () => import('./CourtWizard')
const DualCard = () => import('@/components/Tournament/Duals/DualCard.vue')
const AddCourts = () => import('@/components/Tournament/Brackets/AddCourts.vue')
const TeamMessageButton = () => import('@/components/Notifications/TeamMessageButton')
const MatchPrint = () => import('@/components/Printing/MatchPrint')

export default {
  mixins: [RoundMixin, RouteMixin],
  props: ['bracket', 'showWinners', 'demo', 'round', 'noFabs', 'active', 'courting'],
  data () {
    return {
      loading: false,
      bracketView: true,
      panel: [],
      showMap: false,
      showByes: false,
      showMatch: false,
      selectedMatch: null,
      matchDialog: false,
      matchHeight: 75,
      // matchSpacer: 24,
      lines: [],
      scroll: 0,
      iWinners: true,
      printing: false,
      // hiddenRounds: 0,
      mapper: false,
      printStyle: false,
      highlights: [],
      bracketSearch: true,
      timer: null,
      nums: false,
      places: null
    }
  },
  computed: {
    ...mapGetters(['view', 'tournament', 'getDivision', 'user']),
    useLogos () {
      return this.division && this.division.useLogos
    },
    matchSpacer () {
      return this.hasStreams ? 36 : 24
    },
    hasStreams () {
      return this.tournament &&
        this.tournament.streams &&
        this.tournament.streams.filter(f => f.bracketId && f.bracketId === this.bracket.id).length
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    locked () {
      return !this.demo && (this.tournament.locked || this.division.complete || this.round.locked)
    },
    champText () {
      return this.roundJProps && this.roundJProps.champText
    },
    hiddenRounds () {
      if (this.showByes) return 0
      const rounds = this.isWinners ? this.bracket.winners : this.bracket.losers
      const round = rounds.find(r => {
        const real = r.matches.find(f => !f.isBye)
        if (real) return true
      })
      return round ? round.number : 0
    },
    isWinners () {
      return this.demo ? this.iWinners : this.showWinners
    },
    myKey () {
      return this.bracket.id ? `${this.bracket.id}_${this.isWinners ? 'w' : 'c'}_${this.active}` : `${this.bracket.type}_${this.isWinners ? 'w' : 'c'}_${this.active}`
    },
    rounds () {
      if (!this.bracket) return null
      const result = this.isWinners ? this.bracket.winners : this.bracket.losers
      for (let i = 0; i < this.hiddenRounds; i++) {
        result.shift()
      }
      if (this.bracket.champions > 1) {
        this.bracket.champions >= 2 && result.pop()
        this.bracket.champions >= 3 && result.pop()
        this.bracket.champions >= 5 && result.pop()
        this.bracket.champions >= 9 && result.pop()
      }
      return result
    },
    roundMatches () {
      return flatten(this.rounds.map(m => m.matches))
    },
    courtsUsed () {
      return this.roundMatches && courtSort(this.roundMatches.map(m => m.court))
    },
    poolCourts () {
      return this.division ? courtSort(this.division.poolCourts) : []
    },
    lastRound () {
      return this.rounds.length - 1
    },
    winners () {
      return this.bracket.winners
    },
    roundCss () {
      const css = Array.from({ length: this.rounds.length }, (v, i) => `round-${i + 1}`)
      if (this.bracket.type === 'DOUBLE_ELIM_CROSSOVER') {
        if (this.isWinners) {
          css.splice(css.length - 2, 2, 'crossover', 'finals')
        } else {
          css.splice(css.length - 1, 1, 'crossover')
        }
      } else if (this.bracket.type === 'DOUBLE_ELIM_CROSSOVER_WITH_4TH') {
        if (this.isWinners) {
          css.splice(css.length - 3, 3, 'crossover', 'finals', 'finals')
        } else {
          css.splice(css.length - 1, 1, 'crossover')
        }
      } else if (this.bracket.type.startsWith('SINGLE') && this.bracket.champions === 1) {
        if (['SINGLE_ELIM_WITH_4TH', 'SINGLE_ELIM_5TH_THRU_8TH'].includes(this.bracket.type)) {
          css.splice(css.length - 1, 1, 'finals-bronze')
          css.splice(css.length - 2, 1, 'bronze')
        } else {
          css.splice(css.length - 1, 1, 'finals')
        }
      }

      return css
    },
    spacer () {
      if (!this.rounds) return this.matchSpacer
      return this.rounds.length > 1 && this.rounds[0].matches.length === this.rounds[1].matches.length ? this.matchHeight + this.matchSpacer : this.matchSpacer
    },
    roundProps () {
      const base = `height: ${this.roundHeight}px;`
      const styles = [{ style: base, class: 'round-1' }]
      let r = 0
      let s = 0
      const shiftHeight = (this.matchHeight + this.matchSpacer) / 2
      for (let i = 1; i < this.rounds.length; i++) {
        let c = this.roundCss[i]
        if (this.rounds[i].matches.length === this.rounds[i - 1].matches.length && c !== 'crossover') {
          s++
        } else {
          r++
        }

        let st = c.startsWith('round') ? `${base}${this.padding[r]}${s ? `margin-top: ${s * shiftHeight}px;` : ''}` : `${base}`
        if (c === 'crossover' && !this.isWinners) {
          st = `${base}${s ? `margin-top: ${s * shiftHeight}px;` : ''}`
        }
        if (c === 'bronze' && i === 1) {
          st = `${st}margin-left: 0 !important`
        }
        if (i === this.rounds.length - 1) c += ' last'
        styles.push({
          style: st,
          class: c
        })
      }
      return styles
    },
    padding () {
      return this.roundPadding.map(m => m ? `padding: ${m}px 0;` : '')
    },
    roundHeight () {
      if (!this.rounds) return null
      var matchCount = this.rounds[0].matches.length
      if (this.bracket.type === 'DOUBLE_ELIM_CROSSOVER' && matchCount < 4) matchCount = 4
      if (matchCount === 2 && this.rounds.length > matchCount) matchCount = 3
      if (this.rounds.length > 1 && this.rounds[0].matches.length === this.rounds[1].matches.length && (matchCount !== this.rounds[0].matches.length * 2)) matchCount = matchCount * 2
      const h = (this.matchHeight * matchCount) + (this.matchSpacer * (matchCount - 1))
      return h // > 200 ? h : 200 s
    },
    roundPadding () {
      const total = this.matchHeight + this.spacer

      return [
        0,
        total * 0.5,
        total * 1.5,
        total * 3.5,
        total * 7.5,
        total * 15.5,
        total * 31.5
      ]
    },
    losers () {
      return this.bracket.losers
    },
    teamColors () {
      return [
        'red--text text--darken-4',
        'purple--text',
        'indigo--text text--darken-4',
        'cyan--text',
        'light-green--text text--accent-4',
        'yellow--text text--accent-4',
        'deep-orange--text'
      ]
    },
    matchStyleText () {
      const settings = this.isWinners ? this.bracket.winnersMatchSettings : this.bracket.losersMatchSettings
      if (!settings) return null
      const d = new PoolPlayOptionDescriptor(settings)
      return d.description
    },
    matchD () {
      return [...new Set(this.roundMatches.filter(f => !f.isBye).map(m => m.getMatchDescription(this.bracket)))]
    },
    matchDByR () {
      const arr = this.rounds.map(r => {
        const ds = [...new Set(r.matches.filter(f => !f.isBye).map(m => m.getMatchDescription(this.bracket)))]
        return {
          round: r.number + 1,
          descr: ds.length === 1 ? ds[0] : 'Check the match'
        }
      })
      const grouped = groupby(arr, 'descr')
      const result = []
      for (const a in grouped) {
        result.push({
          desc: a,
          rounds: grouped[a].map(m => m.round)
        })
      }

      return result.length === 1 ? `All Matches Are ${result[0].desc}` : 'Not all matches are played to the same points. Please click the match to see what to play to'
      // result.map((m, i) => {
      //   return i === result.length - 1 ? `All  others are ${result[0].descr}` :
      // })
    },
    addDto () {
      if (!(this.places && this.places.includes(','))) return false
      const places = this.places.split(',')
      if (places.length !== 2) return false
      const nums = this.bracket.allMatches.map(m => +m.number)
      var n = (Math.min(...nums)) - 1
      if (n >= 0) n = -1
      var dto = {
        bracketId: this.bracket.id,
        number: n,
        displayNumber: (Math.max(...this.bracket.allMatches.map(m => +m.displayNumber))) + 1,
        isMatch: this.bracket.winnersMatchSettings.isMatch,
        id: 0
      }
      const h = this.bracket.allMatches.find(f => f.displayNumber === +places[0])
      dto.homeFeeder = h.number
      dto.homeMap = `Loser Match ${h.displayNumber}`
      const a = this.bracket.allMatches.find(f => f.displayNumber === +places[1])
      dto.awayFeeder = a.number
      dto.awayMap = `Loser Match ${a.displayNumber}`

      return dto
    }
  },
  methods: {
    addPlaceMatch () {
      this.loading = true
      this.$VBL.post.matches([this.addDto])
        .then(() => {
          this.places = null
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.loading = false })
    },
    onHighlight (matches) {
      if (this.timer) clearTimeout(this.timer)
      this.highlights = matches
      const target = this.$refs[`match${matches[matches.length - 1]}`]
      if (target) {
        const t = target[0]
        if (t) {
          this.$vuetify.goTo(t, {
            duration: 333,
            offset: 333,
            easing: 'easeInOutCubic'
          })
          // t.$el.scrollIntoView()
        }
      }
      this.timer = setTimeout(() => {
        this.highlights = []
      }, 5000)
    },
    getMap (match) {
      if (!(this.round && this.round.teamsSummary && this.round.teamsSummary.teams)) return null
      const home = match.homeTeam ? this.round.teamsSummary.teams.find(f => f.teamId === match.homeTeam.teamId) : null
      const away = match.awayTeam ? this.round.teamsSummary.teams.find(f => f.teamId === match.awayTeam.teamId) : null
      return {
        home: home ? home.map : null,
        away: away ? away.map : null
      }
    },
    onOpenChange (val) {
      this.mapper = val
    },
    selectMatch (match) {
      if (match) {
        if (this.courting) {
          this.$emit('match-click', match)
          return
        }
        if (this.mapper) {
          this.$refs && this.$refs.courtWiz && this.$refs.courtWiz.addMatch(match)
          return
        }
        if (this.bracket.isDuals) {
          this.$router.push({ query: { dual: match.number } })
        } else {
          this.selectedMatch = match
          this.matchDialog = true
        }
      }
    },
    onMatchSaved () {
      this.matchDialog = false
      this.selectedMatch = null
    },
    openAll () {
      this.panel = this.rounds.map(r => {
        return !!r.matches.findIndex(m => !m.complete)
      })
    },
    feederWasBye (feederN) {
      const match = this.bracket.matches.find(f => f.number === feederN)
      return match && match.isBye
    },
    feederIsSameBracket (feederN) {
      return this.roundMatches.findIndex(f => f.number === feederN) !== -1
    },
    onScroll (e) {
      this.scroll++
    },
    onPrintClick () {
      window.print()
    },
    togglePrintStyle () {
      this.printStyle = !this.printStyle
      const el = this.$refs.bracketContainer
      const bracketEl = this.$refs.bracket
      if (this.printStyle) {
        el.classList.remove('container')
        el.classList.remove('container--fluid')
        el.style.width = `${bracketEl.scrollWidth}px`
      } else {
        el.classList.add('container')
        el.classList.add('container--fluid')
        el.style.removeProperty('width')
      }
    },
    async onPrintClick2 () {
      this.printing = true
      const el = this.$refs.bracketContainer
      const bracketEl = this.$refs.bracket
      el.classList.remove('container')
      el.classList.remove('container--fluid')
      el.style.width = `${bracketEl.scrollWidth}px`

      const options = {
        type: 'dataURL',
        backgroundColor: '#fafafa'
      }
      const output = await this.$html2canvas(el, options)
      el.classList.add('container')
      el.classList.add('container--fluid')
      el.style.removeProperty('width')

      const img = new Image()
      img.src = output
      img.style = 'width: 100%'
      const w = window.open('BracketPrinter')
      img.onload = function () {
        w.print()
        w.close()
      }
      w.document.write(`<div id="printMe" style="width: 8.5in; height: 11in; background: url(${output}); background-repeat:none;background-repeat: no-repeat;background-size: contain;"></div>`)
      w.document.title = 'Bracket Printing'
      const b = w.document.getElementsByTagName('BODY')[0]
      b.style = 'margin:0;; background: #eeeeee;'
      this.printing = false
    }
  },
  watch: {
    bracketView: function (val) {
      if (!val) {
        this.$nextTick(() => {
          this.openAll()
        })
      }
    },
    'bracket.type': function (n, o) {
      if (n && n.startsWith('SINGLE') && this.demo) {
        this.iWinners = true
      }
    }
  },
  components: {
    MatchCard,
    CourtWizard,
    SvgConnector,
    MatchInOutText,
    BracketMatchCard,
    DualCard,
    BracketSearch,
    AddCourts,
    TeamMessageButton,
    MatchPrint
  }
}
</script>

<style scoped>
.hide {
  visibility: hidden;
}
.card {
  height: 75px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.card .team {
  text-align: center;
  width: 100%;
  border-bottom: 2px solid #000;
  flex: 1;
  background-color: #808080;
}
.card .scores {
  flex: 1;
}
.bracket-container {
  overflow-x: auto;
}
.bracket {
  position: relative;
  display: flex;
  font-size: 14px;
}
.bracket .round {
  width: 165px;
  max-width: 165px;
  min-width: 165px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bracket-logo .round {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bracket .round:first-child {
  margin-left: 16px;
}
.bracket .round:last-child {
  margin-right: 165px;
}
.bracket .crossover {
  justify-content: center;
}
.bracket .crossover .match:first-child {
  margin-bottom: 20px;
}
.bracket .finals {
  justify-content: center;
}
.bracket .finals-bronze {
  justify-content: center;
  margin-left: 50px;
}
.bracket .bronze {
  justify-content: center;
  margin-left: -25px;
}
>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
